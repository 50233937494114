<template>
  <el-dialog
    :title="$t('charge.createCharge')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="chargeForm"
      :inline="true"
      :model="chargeInfo"
      size="mini"
      :rules="chargeRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('chargeGroup.name')"
          prop="groupId"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="chargeInfo.groupId"
            :placeholder="$t('system.pleaseSelect') + $t('chargeGroup.name')"
            clearable
            @change="changeChargeGroup"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in chargeGroupList"
              :key="index"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.code
              }}</span></el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('charge.code')"
          prop="code"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeInfo.code"
            :placeholder="$t('system.pleaseInput') + $t('charge.code')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('charge.nameEn')"
          prop="nameEn"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeInfo.nameEn"
            :placeholder="$t('system.pleaseInput') + $t('charge.nameEn')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('charge.nameCn')"
          prop="nameCn"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeInfo.nameCn"
            :placeholder="$t('system.pleaseInput') + $t('charge.nameCn')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('charge.organization')"
          prop="orgId"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="chargeInfo.orgId"
            :placeholder="$t('system.pleaseSelect') + $t('charge.organization')"
            clearable
          >
           <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('charge.remark')"
          prop="remark"
          label-width="120px"
        >
          <el-input
            v-model.trim="chargeInfo.remark"
            :placeholder="$t('system.pleaseInput') + $t('charge.remark')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doCreateCharge" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import baseServer from "../source/basedata";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      chargeInfo: {
        groupId: null,
        groupCode: null,
        groupName: null,
        code: null,
        nameEn: null,
        nameCn: null,

        orgId: null,
        remark: null,
      },
      chargeRules: {
        groupId: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("chargeGroup.name"),
            trigger: "blur",
          },
        ],
        nameEn: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("charge.nameEn"),
            trigger: "blur",
          },
        ],
        nameCn: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("charge.nameCn"),
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("charge.code"),
            trigger: "blur",
          },
        ],

        orgId: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("charge.organization"),
            trigger: "blur",
          },
        ],
      },
      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" },
      // ],
      chargeGroupList: [],
    };
  },
  computed: {
    organizationList(){
      return this.$store.state.constants.saleCompanys;
    },
    isShow() {
      return this.isShowDialog;
    },
  },
  created(){
    this.queryChargeGroup();
  },
  methods: {
    handleClose() {
      this.$emit("onDialogClose");
    },
    queryChargeGroup(){
      baseServer.queryChargeGroup({
        current:1,
        size:10000
      }).then(res=>{
         this.chargeGroupList = res.data ? res.data.records : [];
      })
    },
    changeChargeGroup(val) {
      if (val)
        this.chargeGroupList.some((item) => {
          if (item.id == val) {
            this.chargeInfo = Object.assign({}, this.chargeInfo, {
              groupCode: item.code,
              groupName: item.name,
            });
            return true;
          }
        });
    },
    doCreateCharge() {
      this.$refs["chargeForm"].validate((valid) => {
        if (valid) {
          baseServer.addCharge(this.chargeInfo).then(() => {
            this.$confirm(
              this.$t("charge.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success",
              }
            )
              .then(() => {
                this.$refs["chargeForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
